import React from 'react';
import Page from '../sections/Page';

export default function Home() {

  return (
    <Page
      showHeader={true}
      child={
        <h3 className='page__body'>Welcome to the {`<PrivateRoute/>`} component</h3>
      }
    />
  );
};