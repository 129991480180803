import { useState, useEffect } from 'react';
import axios from 'axios';
import { User } from '../models/User';
import { IUserContext } from './UserContext';

export default function useFindUser(): IUserContext {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios.get<User>('/api/auth/current-user')
      .then(res => {
        setUser(res.data);
        setLoading(false);
      }).catch(err => {
      //console.log(err);
      setLoading(false);
    });
  }, []);

  return {
    user,
    setUser,
    isLoading,
  };
}