import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from './../hooks/useAuth';
import Page from '../sections/Page';
import styled from 'styled-components';
import { Alert, Button, Form, Input } from 'antd';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export default function Login() {
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const { loginUser, error } = useAuth();

  useEffect(() => {
    if (!error) {
      setErrorMessage(null);
      return;
    }
    setErrorMessage(error?.message ?? 'Oops! Some error has ocurred!');
  }, [error]);

  return (
    <Page
      showHeader={false}
      backgroundColor={'#001529'}
      child={
        <Container>
          <Link to={'/'}>
            <Title>Broadband Analyzer</Title>
            <Subtitle>Login</Subtitle>
          </Link>
          <StyledForm
            {...layout}
            name='login'
            onFinish={(values) => loginUser(values)}
          >
            <Form.Item
              label='E-mail'
              name='email'
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <StyledInput bordered={false} />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <StyledPassword bordered={false} />
            </Form.Item>
            <ForgotPassContainer>
              <a href='/recover-password'>Forgot Password?</a>
            </ForgotPassContainer>

            <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
              <StyledButton type='primary' htmlType='submit' shape='round'>
                Log In
              </StyledButton>
              Or <a href='/register'>register now!</a>
            </Form.Item>
            {
              !!errorMessage &&
              <Alert message={errorMessage} type='error' closable onClose={() => setErrorMessage(null)} />
            }
          </StyledForm>
        </Container>
      } />
  );
}

const Container = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  max-width: 70rem;
  box-shadow: 1rem 1rem .5rem 0 #dedede;
  border-radius: 2rem;
  padding: 2rem;
  background-color: #FFFFFF;
`;

const Title = styled.div`
  color: #001529;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .5rem;
  width: 100%;
  text-align: center;
`;

const Subtitle = styled(Title)`
  font-size: 1.4rem;
`;

const StyledButton = styled(Button)`
  width: 100%;

  &.ant-btn-primary {
    background-color: #001529;
    color: #FFFFFF;
    border-color: #001529;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 3rem;
`;
const StyledInput = styled(Input)`
  border-bottom: 1px #001529 solid;

  &:hover {
    border-bottom: 1px #001529 solid;
  }
`;

const StyledPassword = styled(Input.Password)`
  border-bottom: 1px #001529 solid;

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-bottom: 1px #001529 solid;
  }
`;

const ForgotPassContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;