import { useState } from 'react';
import axios from 'axios';
import { SiteAnalysis } from '../models/SiteAnalysis';

export default function usePostgis() {
  const [error, setError] = useState<{ [prop: string]: any; } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>(undefined);

  const siteAnalysis = (data) => {
    setLoading(true);
    axios.post<SiteAnalysis[]>('/api/postgis/site-analysis', data)
      .then((res) => {
        console.log(res.data);
        setResponseData(res.data);
      }).catch((err) => {
      setError(err.response.data?.error);
    }).finally(() => setLoading(false));
  };

  return {
    error,
    loading,
    responseData,
    siteAnalysis,
  };
}