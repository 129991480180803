import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import styled from 'styled-components';

interface IPageProps {
  child: JSX.Element
  showHeader?: boolean
  backgroundColor?: string
  padding?: boolean
}

export default function Page(props: IPageProps): JSX.Element {
  return (
    <StyledLayout>
      {
        !!props.showHeader && <Header />
      }
      <StyledContent backgroundColor={props.backgroundColor} padding={props.padding ?? true}>
        {props.child}
      </StyledContent>
    </StyledLayout>
  );
}

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledContent = styled(Layout.Content)<{ backgroundColor?: string, padding: boolean }>`
  padding: ${props => props.padding ? 2.5 : 0}rem;
  background-color: ${props => props.backgroundColor ?? '#FFFFFF'};
`;