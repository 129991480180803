import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';
import { User } from '../models/User';
import { notification } from 'antd';

export default function useAuth() {
  let history = useHistory();
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState<{ [prop: string]: any; } | null>(null);

  //set user
  const setUserContext = async () => {
    return await axios.get<User>('/api/auth/current-user').then(res => {
      setUser(res.data);
      history.push('/map');
    }).catch((err) => {
      setError(err.response.data?.error);
    });
  };

  //register user
  const registerUser = async (data) => {
    const { username, email, password, passwordConfirm } = data;
    return axios.post(`/api/auth/register`, {
      username,
      email,
      password,
      passwordConfirm,
    }).then(async () => {
      notification.success({
          message: 'Registration Sucessfully!',
          description:
            'Please, go to your inbox to confirm your email before login.',
        },
      );
      history.push('/login');
    })
      .catch((err) => {
        setError(err.response.data?.error);
      });
  };

  const recoverPassword = async (data) => {
      const { email } = data;
      return axios.post(`/api/auth/recover-password`, {
        email,
      }).then(async () => {
        notification.success({
            message: 'Email Sent!',
            description:
              'If we find your data in our database, you will receive a email with a new password.',
          },
        );
        history.push('/login');
      })
        .catch((err) => {
          setError(err.response.data?.error);
        });
    }
  ;

  //login user
  const loginUser = async (data) => {
    const { email, password } = data;
    return axios.post('/api/auth/login', {
      email,
      password,
    }).then(async () => {
      await setUserContext();
    }).catch((err) => {
      setError(err.response.data?.error);
    });
  };

  return {
    registerUser,
    loginUser,
    recoverPassword,
    error,
  };
};
