import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';
import useLogout from '../hooks/useLogout';
import { Button, Layout } from 'antd';
import styled from 'styled-components';

export default function Header() {
  const { user } = useContext(UserContext);
  const { logoutUser } = useLogout();

  return (
    <StyledHeader>
      <Link to={'/'}>
        <Title>Broadband Analyzer</Title>
      </Link>
      <ButtonWrapper>
        {user
          ? <>
            Hello, {user.username}.
            <StyledButton ghost shape='round' onClick={logoutUser}> Log out </StyledButton>
          </>
          : <>
            <Link to={'/login'}>
              <StyledButton type='primary' shape='round'> Login</StyledButton>
            </Link>
            <Link to={'/register'}>
              <StyledButton ghost shape='round'> Register </StyledButton>
            </Link>
          </>
        }
      </ButtonWrapper>
    </StyledHeader>
  );
}

const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  color: #ffffff;
`;

const StyledButton = styled(Button)`
  margin-left: .5rem;
  width: 10rem;

  &.ant-btn-primary {
    background-color: #FFFFFF;
    color: #001529;
    border-color: #001529;
  }
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: .5rem;
  //flex: 5;
`;