import React, { useEffect, useState } from 'react';
import { Autocomplete, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import Loading from '../components/Loading';
import Page from '../sections/Page';
import { Input, Collapse, Descriptions } from 'antd';
import styled from 'styled-components';
import { useDebouncedEffect } from '../hooks/useDebounceEffect';
import usePostgis from '../hooks/usePostgis';

const { Panel } = Collapse;
declare const window: { google: any };

const containerStyle = {
  width: '100%',
  height: '80vh',
};


function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBYG89GTxh8M11beaqklzvhVetA22NoELU',
    libraries: ['places'],
    id: 'google-map-script',
  });

  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<{ lat: number, lng: number } | null>(null);
  const [center, setCenter] = useState({ lat: 40.6643, lng: -73.9385 });
  const [autocomplete, setAutcomplete] = useState<any>(null);
  const [inputLatitude, setInputLatitude] = useState<number | null>(null);
  const { loading, error, responseData, siteAnalysis } = usePostgis();

  const [zoom, setZoom] = useState<number | undefined>(15);
  const [inputLongitude, setInputLongitude] = useState<number | null>(null);
  const [autocompleteText, setAutocompleteText] = useState<string | null>(null);

  useEffect(() => {
    if (!responseData) return;
    console.log('TEM RESULTADO', responseData);

    setMarker({ lat: inputLatitude!, lng: inputLongitude! });

    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(inputLatitude, inputLongitude));
    map!.fitBounds(bounds);
    map!.panToBounds(bounds);
    setZoom(undefined);
  }, [responseData]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  useDebouncedEffect(() => {
    if (inputLatitude == null || inputLongitude == null) return;

    searchData(inputLatitude, inputLongitude);
    // setMarker(true);
  }, 600, [inputLatitude, inputLongitude]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  const searchData = (latitude, longitude) => {
    siteAnalysis({ latitude, longitude });
  };

  return (
    <Page
      showHeader={true}
      padding={false}
      child={
        isLoaded ? (
          <Wrapper>
            <FormWrapper>
              <Container flex={3}>
                <Label>Search for a place:</Label>
                <Autocomplete
                  className={'auto-complete'}
                  onLoad={(autocomplete) => setAutcomplete(autocomplete)}
                  onPlaceChanged={() => {
                    if (autocomplete !== null) {
                      const lat = autocomplete!.getPlace().geometry.location.lat();
                      const lng = autocomplete!.getPlace().geometry.location.lng();
                      setAutocompleteText(autocomplete!.getPlace().formatted_address);
                      setInputLatitude(lat);
                      setInputLongitude(lng);
                    } else {
                      console.log('Autocomplete is not loaded yet!');
                    }
                  }}
                >
                  <Input placeholder={''} value={autocompleteText ?? undefined} onChange={event => {
                    setAutocompleteText(event.target.value);
                  }} />
                </Autocomplete>
              </Container>
              <Container flex={1}>
                <Label>OR</Label>
              </Container>
              <Container flex={2}>
                <Label>Latitude: </Label>
                <Input type={'number'} onChange={event => {
                  const value = +event.target.value;
                  setInputLatitude(isNaN(value) ? null : value);
                }} />
                <Label>Longitude: </Label>
                <Input type={'number'} onChange={event => {
                  const value = +event.target.value;
                  setInputLongitude(isNaN(value) ? null : value);
                }} />
              </Container>
            </FormWrapper>

            {
              loading && <Loading />
            }
            {
              responseData &&
              <PanelDetails>
                <Collapse defaultActiveKey={['1']}>
                  <Panel header='Site Analysis' key='1'>
                    <Descriptions
                      title=''
                      bordered
                      column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >
                      {
                        responseData.map(item => (
                          <Descriptions.Item label={item.title}>{item.value}</Descriptions.Item>))
                      }
                    </Descriptions>
                  </Panel>
                </Collapse>
              </PanelDetails>
            }
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              mapTypeId={'satellite'}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {!!marker && <Marker
                position={marker}
              />}
            </GoogleMap>
          </Wrapper>
        ) : <Loading />
      }
    />
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormWrapper = styled.div`
  display: flex;
  padding: 2rem;
  align-items: center;
  flex-wrap: wrap;
`;

const PanelDetails = styled.div`
  padding: 2rem;
`;

const Container = styled.div<{ flex: number }>`
  flex: ${props => props.flex};
  display: flex;
  min-width: 10rem;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;

  .auto-complete {
    width: 100%;
  }
`;

const Label = styled.label`
  font-weight: bold;
`;

export default React.memo(Map);