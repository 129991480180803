import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from './../hooks/useAuth';
import Page from '../sections/Page';
import styled from 'styled-components';
import { Alert, Button, Form, Input } from 'antd';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

export default function RecoverPassword() {
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const { recoverPassword, error } = useAuth();

  useEffect(() => {
    if (!error) {
      setErrorMessage(null);
      return;
    }
    setErrorMessage(error?.message ?? 'Oops! Some error has ocurred!');
  }, [error]);

  return (
    <Page
      showHeader={false}
      backgroundColor={'#001529'}
      child={
        <Container>
          <Link to={'/'}>
            <Title>Broadband Analyzer</Title>
            <Subtitle>Recover Password</Subtitle>
          </Link>
          <StyledForm
            {...layout}
            name='recover-password'
            onFinish={(values) => recoverPassword(values)}
          >
            <Form.Item
              label='Enter your email'
              name='email'
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <StyledInput bordered={false} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
              <StyledButton type='primary' htmlType='submit' shape='round'>
                Recover
              </StyledButton>
            </Form.Item>
            {
              !!errorMessage &&
              <Alert message={errorMessage} type='error' closable onClose={() => setErrorMessage(null)} />
            }
          </StyledForm>
        </Container>
      } />
  );
}

const Container = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  max-width: 70rem;
  box-shadow: 1rem 1rem .5rem 0 #dedede;
  border-radius: 2rem;
  padding: 2rem;
  background-color: #FFFFFF;
`;

const Title = styled.div`
  color: #001529;
  font-size: 1.8rem;
  text-transform: capitalize;
  letter-spacing: .5rem;
  width: 100%;
  text-align: center;
`;

const Subtitle = styled(Title)`
  font-size: 1.4rem;
`

const StyledButton = styled(Button)`
  width: 100%;

  &.ant-btn-primary {
    background-color: #001529;
    color: #FFFFFF;
    border-color: #001529;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 3rem;
`;
const StyledInput = styled(Input)`
  border-bottom: 1px #001529 solid;

  &:hover {
    border-bottom: 1px #001529 solid;
  }
`;

const StyledPassword = styled(Input.Password)`
  border-bottom: 1px #001529 solid;

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-bottom: 1px #001529 solid;
  }
`;

/*
<div className='inlineForm'>
          <h3>Login</h3>
          <div className='inlineForm__notif'>
            {error && <Error error={error!.message} />}
          </div>
          <form onSubmit={handleLogin}>
            <FormInput type={'text'}
                       placeholder={'Username'}
                       name={'email'}
                       value={values.email}
                       handleChange={handleChange} />
            <FormInput type={'password'}
                       placeholder={'Password'}
                       name={'password'}
                       value={values.password}
                       handleChange={handleChange} />
            <div className='inlineForm__submit'>
              <Link to='/register'>
                <Prompt prompt={'No account? Create one.'} />
              </Link>
              <CTA name={'login'} type={'submit'}
              />
            </div>
          </form>
        </div>
 */