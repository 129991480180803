import React from 'react';
import Page from '../sections/Page';

export default function NotFound() {
  return (
    <Page
      showHeader={true}
      child={
        <div>
          You seem lost.
        </div>
      } />
  );
}